import { MutationTree } from 'vuex';
import Vue from 'vue';

import {
	SET_QUOTATION_PRODUCT,
	DELETE_QUOTATION_PRODUCT,
	RESET_QUOTATION,
	SET_QUOTATION_CACHE,
	DELETE_QUOTATION_CACHE,
	SET_SYNONYMS,
	SET_SHIFT_RESULTS,
	SET_MANUAL_PRICING, SET_QUOTATION
} from './mutationTypes';
import { IQuotation, IShiftProductData, IQuotationProduct, IQuotationCache, IFactSynonyms, IShiftResult } from '@/interfaces';
import getInitialState from './initialState';

const mutations: MutationTree<IQuotation> = {

	[SET_QUOTATION_CACHE](state: IQuotation, payload: IShiftProductData) {
		const selectedIndex: number = state.caches.findIndex((c: IQuotationCache) => c.productID === payload.id);

		if (selectedIndex >= 0) {
			state.caches.splice(selectedIndex, 1);
		}

		state.caches.push({
			order: payload.order,
			index: payload.index,
			productID: payload.id,
			productCode: payload.code,
			storedSession: payload.storedSession,
		});
	},

	[SET_SYNONYMS](state: IQuotation, payload: IFactSynonyms) {
		state.synonyms = payload;
	},

	[SET_QUOTATION_PRODUCT](state: IQuotation, payload: IShiftProductData) {
		const key = payload.id;
		// update product if exist;
		if (state.products[key]) {
			state.products[key] = payload;
		} else { // insert product if not exist;
			const quotationProduct: IQuotationProduct = {};
			quotationProduct[key] = payload;
			state.products = Object.assign(quotationProduct, state.products);
		}
	},

	[DELETE_QUOTATION_PRODUCT](state: IQuotation, productID: string) {
		Vue.delete(state.products, productID);
	},

	[DELETE_QUOTATION_CACHE](state: IQuotation, productID: string) {
		const selectedIndex: number = state.caches.findIndex((c: IQuotationCache) => c.productID === productID);

		if (selectedIndex >= 0) {
			state.caches.splice(selectedIndex, 1);
		}
	},

	[RESET_QUOTATION](state: IQuotation) {
			Object.assign(state, getInitialState());
	},

	[SET_SHIFT_RESULTS](state: IQuotation, payload: IShiftResult[]) {
		state.shiftResults = payload;
	},

	[SET_MANUAL_PRICING](state: IQuotation, payload: any) {
		for (const key in state.products) {
			if (Object.prototype.hasOwnProperty.call(state.products, key)) {
				// Landlords with external pricing does not support real time pricing.
				if (payload.allowExternalPricing) {
					state.products[key].price.needsManualPricing = payload.value;
				}
			}
		}
	},

	[SET_QUOTATION](state: IQuotation, payload: any) {
		// although here is a loop, but supporting multiple products in DOM segment is not tested.
		for (const key in state.products) {
			if (Object.prototype.hasOwnProperty.call(state.products, key)) {
				// Landlords with external pricing does not support real time pricing.
				if (payload.allowExternalPricing) {
					state.products[key].underwriting = payload.underwriting;
					state.products[key].price = payload.pricing;
				}
			}
		}
	}
};

export default mutations;
