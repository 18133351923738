import _ from 'lodash';
import sha256 from 'sha256';
import store from '@/store';
import config from '@/config';
import QuoteProduct from '@/models/QuoteProduct';
import moment from 'moment';

const configTypes = {
	INIT_STEP: 'INIT_STEP',
	INIT_CONFIGURATION: 'INIT_CONFIGURATION',
	ADD_CONFIGURATION: 'ADD_CONFIGURATION',
	IMPRESSION_CONFIGURATION: 'IMPRESSION_CONFIGURATION',
	CONFIGURED_CONFIGURATION: 'CONFIGURED_CONFIGURATION',
	INIT_COVERAGE_DETAILS: 'INIT_COVERAGE_DETAILS',
	INIT_VERIFICATION: 'INIT_VERIFICATION',
	INIT_SUMMARY: 'INIT_SUMMARY',
	INIT_PAYMENT: 'INIT_PAYMENT',
	INIT_CONFIRMATION: 'INIT_CONFIRMATION',
	INIT_USER: 'INIT_USER',
	INIT_CONTINUATION: 'INIT_CONTINUATION'
};

const customTypes = {
	COVERAGE_DURATION: (product: QuoteProduct, payload: any = null) => (product && product.policyStartDate && product.policyEndDate ?
		`${moment(product.policyEndDate, 'YYYY-MM-DD').diff(moment(product.policyStartDate, 'YYYY-MM-DD'), 'days')}` :
		null),
	NUMBER_ASSETS: (product: QuoteProduct, payload: any = null) => {
		if (product && product.customProductTree) {
			let numAssets = 0;
			_.forEach(product.customProductTree.components, (component) => {
				component.assets.forEach((asset) => {
					if (asset.type === payload) {
						numAssets += 1;
					}
				});
			});
			return `${numAssets}`;
		} else {
			return '0';
		}
	}
};

const ODLog = (payload: any) => {
	(window as any).dataLayer = (window as any).dataLayer || [];
	(window as any).dataLayer.push(payload);
};

const ODReviewOption = (payload: any, configType: string, boolType: boolean = false, isContinuation: boolean = false) => {
  const isValid = configType === configTypes.CONFIGURED_CONFIGURATION || configType === configTypes.INIT_COVERAGE_DETAILS ? !payload.isReviewRequired : store.getters['quotation/isPriceValidByProductId'](payload.id, isContinuation);
  return boolType ? !isValid : !isValid ? 'Yes' : 'No';
};

const FormatODCartItems = async (payload: any[], configType: string, isContinuation: boolean = false) => {

	const products: any[] = [];
	for (const index in payload) {
		if (parseInt(index, 10) < 0) {
			continue;
		}
		const category = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
		const p = payload[index];
		let data: any = {
			id: p.code,
			name: p.name || p.product.name,
			price: ODReviewOption(p, configType, true, isContinuation) ? '0.00' : `${p.price.totalPrice - p.price.refund}`,
			brand: (config as any).organisation,
			category,
			quantity: 1
		};
		data[_.get(dimensions(), 'cd_requires_review', 'cd_requires_review')] = ODReviewOption(p, configType);
		const productODConfig = store.getters['app/getODConfig'](configType, p.code);
		/*
		EXAMPLE: ShiftMappings config (VARIANT | FACT | BENEFIT | CUSTOM)
		"PET-PPI|gl=COMPONENT:base.base.limit|pi='2'|oo=COMPONENT:base.base.owner.operator" = variant: 'gl=10M-2M|pi=2\oo=2' // VARIANT
		"PET-PPI#cd_annual_turnover=COMPONENT:base.base.annual.turnover" = cd_annual_turnover: '100000' // FACT
		"PET-PPI#cd_deductible=BENEFIT@COMPONENT:base.base.deductible" // BENEFIT
    "PET-PPI#cd_coverage_duration=CUSTOM@COVERAGE_DURATION" // CUSTOM
    "PET-PPI#cd_coverage_duration=CUSTOM@NUMBER_ASSETS:vehicle" // CUSTOM WITH PAYLOAD
		*/

		// variant | custom dimension
		const variance = await FormatODFactsData(productODConfig, p);
		data = {...data, ...variance};
		if (p.policyNumber) {
			data[_.get(dimensions(), 'cd_policy_no', 'cd_policy_no')] = p.policyNumber;
		}
		products.push(data);
	}
	return products;
};

const FormatODFactsData = async (productODConfig: any, product: QuoteProduct) => {
	const data: any = {};
	for (const cf of productODConfig) {
		if (cf.includes('|')) {
			const variantOptions = cf.split('|');
			const productCode = variantOptions.shift(); // remove product code
			if (!product || productCode !== product.code) {
				continue;
			}

			let variant: string | null = null;
			for (const variantData of variantOptions) {
				const temp = variantData.split('=');
				const variantID = temp[0];
				const variantFact = temp[1];
				variant = await FormatVariant(product, variantID, variantFact, variant, true);
			}
			if (variant) {
				data.variant = variant;
			}
		} else {
			const custom = cf.split('#');
			if (!product || custom[0] !== product.code) {
				continue;
			}
			const customData = custom.pop();
			const temp = customData.split('=');
			const customID = _.get(dimensions(), temp[0], temp[0]);
			const customFact = temp[1];
			const info = await FormatVariant(product, customID, customFact);
			if (!_.isEmpty(info) && info !== '0' && info !== 'null' && info !== 'undefined') {
				data[customID] = info;
			}
		}
	}
	return data;
};

const FormatVariant = async (product, variantID, variantFact, currentVariant: string | null = '', isVariant: boolean = false) => {
	let variant = currentVariant;
	if (variantFact && variantID) {
		let value = variantFact;
		if (variantFact.includes('\'')) {
			value = variantFact.replaceAll('\'', '');
		} else {
			const fact = product && product.customAllFacts && await product.customAllFacts.find((f) => f.id === variantFact);
			let type = '';
			if (variantFact.includes('@')) {
				const variantTemp = variantFact.split('@');
				type = variantTemp[0];
				variantFact = variantTemp[1];
			}
			switch (type) {
				case 'BENEFIT':
					const tree = product.customProductTree;
					const benefits = [...tree.benefits, ..._.flatten(_.map(tree.components, (comp) => comp.benefits))];
					const benefit = benefits.find((b) => b.id === variantFact);
					if (benefit) {
						value = benefit.value;
					}
					break;
				case 'CUSTOM':
					const customInfo = variantFact.split(':');
					const customType = customInfo[0];
					const customData = customInfo.length > 1 ? customInfo[1] : null;
					value = customTypes[customType] ? customTypes[customType](product, customData) : null;
					break;
				default:
					if (fact.formatType === 'list') {
						const option = fact.options.find((o) => o.value === fact.currentValue);
						if (option) {
							value = option.name;
						}
					} else {
						value = fact.currentValue;
					}
			}
		}
		if (value !== null && value !== undefined) {
			value = value && value.replaceAll('$', '').replaceAll(',', '');
			if (!_.isEmpty(variant)) {
				variant += '|';
				variant += `${variantID}=${value}`;
			} else {
				variant = `${isVariant ? variantID + '=' : ''}${value}`;
			}
		} else {
			variant = null;
		}
	}
	return variant;
};

const dimensions = () => _.get(store.state.app.config.shiftMappings, 'OD_LOG.DIMENSIONS', {});

export default class GAUtil {

  public static logGAPageView(self, path?, page?) {
    if (!self.$gtag || !self.$gtag.pageview) {
      return;
    }
    self.$gtag.pageview({
      page_path: path ? path : self.$route.path,
      page_title: page ? `${document.title}+${page}` : document.title,
      page_location: window.location.href
    });
  }

  public static logGAEvent(self, action, category, label, value?) {
    if (!self.$gtag || !self.$gtag.event) {
      return;
    }
    self.$gtag.event(action, {
      event_category: category,
      event_label: label,
      value
    });
  }

	// INIT_STEP
	public static ODLogPageView(payload: IODPageViewData) {
		const cnf = store.getters['app/getODConfig'](configTypes.INIT_STEP);
		if (!cnf) {
			return;
		}

		const scheme = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
		const data: any = {
			event: 'virtualPageview',
			...payload,
			language: _.get(store, 'state.app.config.language', 'EN'),
			scheme,
			customer_segment: cnf.CUSTOMER_SEGMENT
		};
		ODLog(data);

		GAUtil.ODLogUserData();
	}

	// INIT_CONFIGURATION
	public static async ODLogSelectedProducts(input?: QuoteProduct[]) {
		const payload = input || store.getters['quotation/getSortedQuotationProduct']();
		if (!payload || !payload.length) {
			return;
		}
		const cnf = store.getters['app/getODConfig'](configTypes.INIT_CONFIGURATION);
		if (!cnf) {
			return;
		}
		const category = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
		const products: any[] = [];
		for (const p of payload) {
			const data: any = {
				id: p.code,
				name: p.name || p.product.name,
				price: `${p.currentPrice ? p.currentPrice : '0.00'}`,
				brand: (config as any).organisation,
				category,
				...await FormatODFactsData(cnf, p)
			};
			products.push(data);
		}

		ODLog({
      		event: 'productDetail',
			ecommerce: {
				currencyCode: (config as any).currencyCode,
				detail: {
					products
				}
			}
    	});
	}

	// ADD_CONFIGURATION
	public static async ODLogAddProduct(payload: any, position: number, step: 'landing' | 'configuration') {
		if (!payload) {
			return;
		}
		const cnf = store.getters['app/getODConfig'](configTypes.ADD_CONFIGURATION);
		if (!cnf) {
			return;
		}
		let listText = 'Add Coverage Block';
		switch (step) {
			case 'landing':
				listText = 'Landing Page';
				break;
		}
		const category = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
		const data: any = {
			id: payload.code,
			name: payload.product.name,
			price: `${payload.currentPrice ? payload.currentPrice : '0.00'}`,
			brand: (config as any).organisation,
			category,
			position: position + 1,
			...await FormatODFactsData(cnf, payload)
		};
		const products = [data];

		ODLog({
      		event: 'productClick',
			ecommerce: {
				currencyCode: (config as any).currencyCode,
				click: {
					actionField: {list: listText},
					products
				}
			}
    });
	}

	// IMPRESSION_CONFIGURATION
	public static async ODLogProductsImpression(step: 'landing' | 'configuration', input?: any[]) {
		const payload = input || store.getters['quotation/getSortedQuotationProduct']();
		if (!payload || !payload.length) {
			return;
		}
		const cnf = store.getters['app/getODConfig'](configTypes.IMPRESSION_CONFIGURATION);
		if (!cnf) {
			return;
		}

		let listText = 'Add Coverage Block';
		switch (step) {
			case 'landing':
				listText = 'Landing Page';
				break;
		}

		const products: any[] = [];
		for (const index in payload) {
			if (!index) {
				continue;
			}
			const p = payload[index];
			let quoteProduct: any = null;
			if (p.id && p.code && p.definition) {
				quoteProduct = new QuoteProduct(p.id, p.code, p.definition);
			}
			if (quoteProduct) {
				await quoteProduct.describeProduct();
			}
			const category = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
			const data: any = {
				id: p.code,
				name: p.name || p.product.name,
				price: `${p.currentPrice ? p.currentPrice : '0.00'}`,
				brand: (config as any).organisation,
				category,
				position: parseInt(index, 10) + 1,
				list: listText,
				...await FormatODFactsData(cnf, quoteProduct)
			};
			products.push(data);
		}
		ODLog({
      		event: 'productImpressions',
			ecommerce: {
				currencyCode: (config as any).currencyCode,
				impressions: products
			}
    });
	}

	public static async ODLogProductsConfigured(input?: QuoteProduct[]) {
		const payload = input || store.getters['quotation/getSortedQuotationProduct']();
		if (!payload || !payload.length) {
			return;
		}
		const cnf = store.getters['app/getODConfig'](configTypes.CONFIGURED_CONFIGURATION);
		if (!cnf) {
			return;
		}
		const products = await FormatODCartItems(payload, configTypes.CONFIGURED_CONFIGURATION);

		ODLog({
      		event: 'addToCart',
			ecommerce: {
				currencyCode: (config as any).currencyCode,
				add: {
					products
				}
			}
    });
	}

	public static async ODLogProductsCoverageDetails(input?: QuoteProduct[]) {
		const payload = input || store.getters['quotation/getSortedQuotationProduct']();
		if (!payload || !payload.length) {
			return;
		}

		const cnf = store.getters['app/getODConfig'](configTypes.INIT_COVERAGE_DETAILS);
		if (!cnf) {
			return;
		}
		const products = await FormatODCartItems(payload, configTypes.INIT_COVERAGE_DETAILS);

		ODLog({
      		event: 'checkout',
			ecommerce: {
				checkout: {
       		actionField: {step: 1},
					products
				}
			}
    });
	}

	public static async ODLogVerification() {
		const cnf = store.getters['app/getODConfig'](configTypes.INIT_VERIFICATION);
		if (!cnf) {
			return;
		}

		const payload = store.getters['quotation/getSortedQuotationProduct']();
		if (!payload || !payload.length) {
			return;
		}
		const applicantInfo: any = {
			renewal: store.state.app.isRenewal ? 'Yes' : 'No'
		};
		const data = await FormatODFactsData(cnf, payload[0]);
		_.forEach(data, (value, key) => {
			applicantInfo[key] = value === 'true' || value === 'false' ? value === 'true' ? 'Yes' : 'No' : value;
		});
		ODLog({
			event: 'applicantInfo',
			...applicantInfo
		});

		ODLog({
      		event: 'checkout',
			ecommerce: {
				checkout_option: {
					actionField: {step: 1, option: `Renewal-${store.state.app.isRenewal ? 'Yes' : 'No'}`}
				},
				checkout: {
       		actionField: {step: 2}
				}
			}
    });
	}

	public static async ODLogSummary(input?: QuoteProduct[]) {
		const payload = input || store.getters['quotation/getSortedQuotationProduct']();
		if (!payload || !payload.length) {
			return;
		}
		const cnf = store.getters['app/getODConfig'](configTypes.INIT_SUMMARY);
		if (!cnf) {
			return;
		}
		const products = await FormatODCartItems(payload, configTypes.INIT_SUMMARY);

		ODLog({
      		event: 'checkout',
			ecommerce: {
				checkout: {
       		actionField: {step: 3},
					products
				}
			}
    });
	}

	public static ODLogPayment(isContinuation: boolean = false) {
		const cnf = store.getters['app/getODConfig'](configTypes.INIT_PAYMENT);
		if (!cnf) {
			return;
		}

		ODLog({
      		event: 'checkout',
			ecommerce: {
				checkout: {
       		actionField: {step: 4}
				}
			}
    });

		if (isContinuation) {
			GAUtil.ODLogContinuation();
		}
	}

	public static async ODLogConfirmation(transaction: IODTransactionData, input?: QuoteProduct[], isContinuation?: boolean) {
		let payload = input || store.getters['quotation/getSortedQuotationProduct']();
		payload = _.filter(payload, (p) => !ODReviewOption(p, configTypes.INIT_CONFIRMATION, true, isContinuation));

		const cnf = store.getters['app/getODConfig'](configTypes.INIT_CONFIRMATION);
		if (!cnf) {
			return;
		}
		const products = await FormatODCartItems(payload, configTypes.INIT_CONFIRMATION, isContinuation);

		// GA: log current step started
		const data: IODPageViewData = {
			pagePath: `/portal/confirmation`,
			pageTitle: 'Confirmation'
		};
		this.ODLogPageView(data);

		ODLog({
      		event: 'transactionComplete',
			ecommerce: {
				purchase: {
       		actionField: transaction,
					products
				}
			}
    });
	}

	public static async ODLogUserData(inputEmail?: string) {
		const product = store.getters['quotation/getSortedQuotationProduct']()[0];
		const emailFact = store.getters['app/getODConfig'](configTypes.INIT_USER);
		const email = inputEmail || (product && await product.getFactValue(emailFact));
		if (email) {
			const data: any = {
				event: 'userIdentify',
				email: sha256(email)
			};
			ODLog(data);
			// const userIDData: any = {
			// 	event: 'hashCompleted',
			// 	hashed_email: sha256(email)
			// };
			// ODLog(userIDData);
		}
	}

	public static async ODLogContinuation() {
		const cnf = store.getters['app/getODConfig'](configTypes.INIT_CONTINUATION);
		if (!cnf) {
			return;
		}

		const data: any = {
      		event: 'continuationSession',
			continuation: true
    };
		ODLog(data);
	}
}

export interface IODPageViewData {
	pagePath: string;
  pageTitle: string;
}

export interface IODApplicantData {
	suburb_city: string;
	state_province: string;
	renewal: string;
	org_type: string;
	assoc_membership: string;
}

export interface IODTransactionData {
	id: string;
	affiliation: string;
	revenue: string;
	tax: string;
	// coupon?: string;
}
